<template>
  <div class="mailing-commercial">
    <v-tooltip bottom color="#311B92">
      <template v-slot:activator="{ on, attrs }">
        <div
          size="sm"
          class="block-icon mr-2"
          v-on="on"
          v-bind="attrs"
          @click="openModal"
        >
          <font-awesome-icon icon="envelope" />
        </div>
      </template>
      <span>Envoyer E-mail</span>
    </v-tooltip>
    <mail-vendeur
      @eventMailSendRefresh="eventMailSendRefresh = true"
      :isOpen="isOpen"
      @custumChange="closeModal"
      :dataToUse="getDataMailAppelCommercial"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MailVendeur from './mailVendeur.vue';
export default {
  props: {
    dataToUse: { required: true },
    selecteur: { default: 'id' }
  },
  data() {
    return {
      isOpen: false,
      eventMailSendRefresh: false
    };
  },
  computed: {
    ...mapGetters(['getDataMailAppelCommercial'])
  },
  methods: {
    ...mapActions([
      'fetchDataForMailAppelCommercial',
      'resetDataModalMailAppelPaiementCommercial'
    ]),
    openModal() {
      this.isOpen = true;
      this.fetchDataForMailAppelCommercial({
        data: this.dataToUse.map(item => item[this.selecteur])
      });
    },
    closeModal() {
      this.isOpen = false;
      if (this.eventMailSendRefresh == true) {
        this.$emit('eventMailSendRefreshTable');
      }
      this.eventMailSendRefresh = false;
      this.resetDataModalMailAppelPaiementCommercial();
    }
  },
  components: {
    MailVendeur
  }
};
</script>

<style scoped lang="scss">
.mailing-commercial {
}
</style>
